<template>
  <div class="Result">
    <div class="Result_h">
      <div class="Result_box" v-if="finishOrWait == 1">
        <van-image fit="cover" :src="require('@/assets/img/reply.png')" />
        <h4>提交成功</h4>
        <div class="Result_content">
          <p>您的问题已提交申请，等待系统处理</p>
          <p>您可以通过公众号的个人中心查看进度及结果</p>
        </div>
      </div>
      <div class="Result_box" v-else>
        <van-image
          fit="cover"
          :src="require('@/assets/img/PolicyLibrary_03.jpg')"
        />
        <h4>提交失败</h4>
        <div class="Result_content">
          <p>这里是提示语</p>
          <p>这里是提示语这里是提示语</p>
        </div>
      </div>
      <div class="Result_bottom">
        <van-button round type="info" @click="$router.push('/policyLibrary')"
          >返回</van-button
        >
      </div>
    </div>

    <copyrightIp />
  </div>
</template>
<script>
import { Icon, Image as VanImage, Button } from "vant";
import copyrightIp from "@/components/copyrightIp";
export default {
  components: {
    [Icon.name]: Icon,
    [VanImage.name]: VanImage,
    [Button.name]: Button,
    copyrightIp,
  },
  data() {
    return {
      finishOrWait: 1,
    };
  },
  mounted() {
    this.finishOrWait = this.$route.query.state;
  },
};
</script>
<style lang="scss" scoped>
.Result {
  width: 100%;
  height: 100%;
  background: #fff;
  .Result_h {
    min-height: calc(100% - 44px);
  }
  .Result_box {
    width: 100%;
    text-align: center;
    padding-top: 100px;
    ::v-deep .van-image {
      width: 150px;
    }

    h4 {
      font-size: 25px;
      padding: 8px 0;
    }
    .Result_content {
      font-size: 12px;
      // color: #afacac;
      .blue {
        color: blue;
      }
    }
  }
  .Result_bottom {
    width: 100%;
    text-align: center;
    padding: 30px 20px;
    .van-button {
      width: 50%;
    }
  }
}
</style>
